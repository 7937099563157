import { Box, Text, HStack, Stack, Grid, GridItem, Collapse, IconButton, Heading } from '@chakra-ui/react';
import { useGetEmployeeOnboardingRequestApprovalListQuery } from '../../../app/services/dme/api/employeeOnboardingRequest';
import { useAppSelector } from '../../../app/state/hooks';
import { useState } from 'react';
import { IoContract, IoExpand } from 'react-icons/io5';
import { MdCompress, MdExpand } from 'react-icons/md';

type Props = {};

const EmployeeOnboardingRequestApproval = (props: Props) => {
  //get user info
  const { logonUser } = useAppSelector(s => s.user);
  const userId = logonUser?.ref_user_id ?? 0;

  const { data, isLoading, isFetching } = useGetEmployeeOnboardingRequestApprovalListQuery(userId);
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  if (isLoading || isFetching) return <div>Loading...</div>;

  //Add a check to ensure data is not undefined or null
  if(!data || !data.first_dataset_model  || data.first_dataset_model.length === 0){
    return <div> No data available</div>
  }

  const formatDate = (date: string) => {
    if (!date) return '-';
    return date.split('T')[0]; // Get the date part (YYYY-MM-DD)
  };

   
  return (
    <>
      <Box p="3">
        {/* Onboarding Section */}
        <Box maxH= '100%' overflowY="auto">
          <Heading size="md" mb={4}>Onboarding Requests</Heading>
          {data.first_dataset_model.filter(d => d.start_date !== null).length > 0 ? (
            data.first_dataset_model.filter(d => d.start_date !== null).map((d, i) => (
              <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
                <HStack alignItems="start">
                  <Box>
                    <HStack flexShrink={0} mr="2">
                      <IconButton
                        aria-label={expanded[d.eo_request_id] ? 'contract card' : 'expand card'}
                        title={expanded[d.eo_request_id] ? 'Collapse' : 'Expand'}
                        size="sm" icon={expanded[d.eo_request_id] ? <MdCompress /> : <MdExpand />}
                        onClick={() =>
                          setExpanded(e => ({
                            ...e,
                            [d.eo_request_id]: !e[d.eo_request_id]
                          }))
                        }
                      />
                    </HStack>
                  </Box>
                  <Stack w="100%">
                    <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                      <Field title="Request ID" value={d.eo_request_id} />
                      <Field title="First Name" value={`${d.first_name}`} />
                      <Field title="Last Name" value={`${d.last_name}`} />
                      <Field title="Employee Type" value={d.employee_type? d.employee_type:'-'} />
                      <Field title="Start Date" value={formatDate(d.start_date)} />
                      <Field title="End Date" value={formatDate(d.end_date)} />
                    </Grid>
                    <Collapse in={expanded[d.eo_request_id]}>
                      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                        <Field title="Personal Email Address" value={d.personal_email_address} />
                        <Field title="Contact Number" value={d.contact_number? d.contact_number:'-'} />
                        <Field title="Geographical Location" value={d.geographical_location? d.geographical_location: '-'} />
                        <Field title="Address 1" value={d.shipping_address_line_1 ? d.shipping_address_line_1: '-'} />
                        <Field title="Address 2" value={d.shipping_address_line_2 ? d.shipping_address_line_2: '-'} />
                        <Field title="City" value={d.shipping_address_city ? d.shipping_address_city : '-' } />
                      </Grid>
                      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                        <Field title="State" value={d.shipping_address_state? d.shipping_address_state: '-'} />
                        <Field title="Country" value={d.shipping_address_country ? d.shipping_address_country : '-' } />
                        <Field title="ZIP Code" value={d.shipping_address_zip ? d.shipping_address_zip: '-'} />
                        <Field title="Squad" value={d.squad? d.squad:'-'} />
                        <Field title="Specialty" value={d.specialty? d.specialty: '-'} />   
                        <Field title="Needs Virtual Machine?" value={d.virtual_machine_flag? 'YES':'NO'} />
                      </Grid>
                      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
                        <Field title="Requested by" value={d.requested_by? d.requested_by: '-'} />
                        <Field title="Requested Date" value={d.row_created_datetime_utc? d.row_created_datetime_utc: '-'} />
                        <Field title="Additional Notes" value={d.additional_notes? d.additional_notes: '-'} colSpan={4}/>
                      </Grid>
                    </Collapse>
                  </Stack>
                </HStack>
              </Box>
            ))):(
              <Text style={{ marginLeft: 20}}>No data found</Text>
          )}
        </Box>

        {/*Divider */}
        <hr style={{
            border: "1px solid brand.main.default",
            marginBottom: '10px',
            marginTop: '10px',
            width: '100%',
        }}/>

        {/* Offboarding Section */}
        <Box maxH="100%" overflowY="auto">
          <Heading size="md" mb={4}>Offboarding Requests</Heading>
          {data.first_dataset_model.filter(d => d.end_date !== null  && d.start_date === null).length > 0 ? (
            data.first_dataset_model.filter(d => d.end_date !== null && d.start_date === null).map((d, i) => (
              <Box p={3} my={4} shadow="md" border="1px" borderColor="gray.200" borderRadius={3} key={i}>
                <HStack alignItems="start">
                <Box>
                    <HStack flexShrink={0} mr="2">
                      <IconButton
                        aria-label={expanded[d.eo_request_id] ? 'contract card' : 'expand card'}
                        title={expanded[d.eo_request_id] ? 'Collapse' : 'Expand'}
                        size="sm" icon={expanded[d.eo_request_id] ? <MdCompress /> : <MdExpand />}
                        onClick={() =>
                          setExpanded(e => ({
                            ...e,
                            [d.eo_request_id]: !e[d.eo_request_id]
                          }))
                        }
                      />
                    </HStack>
                  </Box>
                  <Stack w="100%">
                    {/* <Text fontSize="inherit" fontWeight="bold">Offboarding</Text> */}
                    <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                      <Field title="Request ID" value={d.eo_request_id} />
                      <Field title="First Name" value={`${d.first_name}`} />
                      <Field title="Last Name" value={`${d.last_name}`} />
                      <Field title="End Date" value={formatDate(d.end_date)} />
                    </Grid>
                    <Collapse in={expanded[d.eo_request_id]}>
                      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
                        <Field title="Requested by" value={d.requested_by? d.requested_by: '-'} />
                        <Field title="Requested Date" value={d.row_created_datetime_utc? d.row_created_datetime_utc : '-'} />
                      </Grid>
                    </Collapse>
                  </Stack>
                </HStack>
              </Box>
            ))):(
              <Text style={{ marginLeft: 20}}>No data found</Text>
          )}
        </Box>
      </Box>
    </>
  );
};

type FieldProps = {
  title: string;
  value: any;
  w?: string | number;
  maxW?: string | number;
  colSpan?: number;
};
function Field({ title, value, w, maxW, colSpan }: FieldProps) {
  return (
    <GridItem colSpan={colSpan}>
      <Stack w={w} maxW={maxW} spacing={0}>
        <Text fontWeight="bold">{title}</Text>
        <Text>{value}</Text>
      </Stack>
    </GridItem>
  );
}

export default EmployeeOnboardingRequestApproval;